import React, { useState, useEffect } from "react";
import Layout from "../components/partials/layout";
import PageHeader from "../components/elements/page-header";
import { useTranslation } from "react-i18next";
import ServiceItem from "../components/elements/service-item";
import axios from "axios";
import i18n from "i18next";
import moment from "moment";

function ServiceView() {
  const { t } = useTranslation();
  const [services, setServices] = useState([]);
  const activeLanguage = i18n.language;
  moment.locale(activeLanguage);

  const getDatas = async () => {
    try {
      const result = await axios.get(
        process.env.REACT_APP_API_URL + "services"
      );

      if (result && result.data) {
        const data = result.data;

        if (data.status) {
          const blogDatas = data.data.filter(
            (x) => x.lang_code === activeLanguage
          );
          setServices(blogDatas);
        } else {
          setServices([]);
        }

        // if (status) {
        //   // const blogDatas = data.filter((x) => x.lang_code === activeLanguage);
        //   // setServices(blogDatas);
        // } else {
        //   console.log(message);
        //   setServices([]);
        // }
      }
    } catch (error) {
      console.log(error);
      setServices([]);
    }
  };

  useEffect(() => {
    getDatas();
  }, [activeLanguage]);
  return (
    <Layout pageTitle={t("global_header_services")}>
      <div>
        <PageHeader
          page={t("global_header_services")}
          home={t("global_header_home")}
          text={" - " + t("global_header_services")}
          image="about-image"
        />
      </div>

      <div className="md:flex flex-wrap mb-10 md:container md:mx-auto px-5">
        {services.map((service, index) => {
          const small = process.env.REACT_APP_API_URL + service.small;
          return (
            <ServiceItem
              key={index}
              title={service.title}
              description={service.description}
              image={small}
              slug={service.slug}
            />
          );
        })}
      </div>
    </Layout>
  );
}
export default ServiceView;
